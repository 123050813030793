@import "weui_font";

[class^="weui_icon_"]:before, [class*=" weui_icon_"]:before {
    margin: 0;
}

:before {
    .weui_icon_success& {
        font-size: 23px;
        color: #09BB07;
    }
    .weui_icon_waiting& {
        font-size: 23px;
        color: #10AEFF;
    }
    .weui_icon_warn& {
        font-size: 23px;
        color: #F43530;
    }
    .weui_icon_info& {
        font-size: 23px;
        color: #10AEFF;
    }

    .weui_icon_success_circle& {
        font-size: 23px;
        color: #09BB07;
    }
    .weui_icon_success_no_circle& {
        font-size: 23px;
        color: #09BB07;
    }
    .weui_icon_waiting_circle& {
        font-size: 23px;
        color: #10AEFF;
    }
    .weui_icon_circle& {
        font-size: 23px;
        color: #C9C9C9;
    }
    .weui_icon_download& {
        font-size: 23px;
        color: #09BB07;
    }

    .weui_icon_info_circle& {
        font-size: 23px;
        color: #09BB07;
    }

    .weui_icon_safe_success& {
        color: #09BB07;
    }
    .weui_icon_safe_warn& {
        color: #FFBE00;
    }

    .weui_icon_cancel& {
        color: #F43530;
        font-size: 22px;
    }

    .weui_icon_search& {
        color: #B2B2B2;
        font-size: 14px;
    }

    .weui_icon_clear& {
        color: #B2B2B2;
        font-size: 14px;
    }
}

:before {
    .weui_icon_msg& {
        font-size: 104px;
        .weui_icon_warn& {
            color: #F76260;
        }
    }
}

:before {
    .weui_icon_safe& {
        font-size: 104px;
    }
}