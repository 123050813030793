@import "fn";

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    line-height: 1.6;
    font-family: @sansFont;
}

* {
    margin: 0;
    padding: 0;
}

a img {
    border: 0;
}

a {
    text-decoration: none;
}