@import "../../../base/fn";

.weui_vcode {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    .weui_cell_ft {
        img {
            margin-left: 5px;
            height: @weuiCellHeight;
            vertical-align: middle;
        }
    }
}