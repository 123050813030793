@import "../../../base/fn";

.weui_check_label {
    .setTapColor();
}

.weui_check{
    position: absolute;
    left: -9999em;
}
