@import "../../base/fn";

@weuiUploaderBorderColor:#D9D9D9;
@weuiUploaderActiveBorderColor:#999999;
@weuiUploaderFileSpacing: 9px;
@weuiUploaderSize: 79px;
@weuiUploaderBorderWidth: 1px;

.weui_uploader{}
.weui_uploader_hd{
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    .weui_cell_ft{
        font-size: 1em;
    }
}
.weui_uploader_bd{
    margin-bottom: @weuiCellGapH - (@weuiCellGapV + @weuiUploaderFileSpacing);
    margin-right: -@weuiUploaderFileSpacing;
    overflow: hidden;
}

.weui_uploader_files{
    list-style: none;
}
.weui_uploader_file{
    float: left;
    margin-right: @weuiUploaderFileSpacing;
    margin-bottom: @weuiUploaderFileSpacing;
    width: @weuiUploaderSize;
    height: @weuiUploaderSize;
    background: no-repeat center center;
    background-size: cover;
}
.weui_uploader_status{
    position: relative;
    &:before{
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
    }
    .weui_uploader_status_content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
    }
    .weui_icon_warn{
        display: block;
    }
}

.weui_uploader_input_wrp{
    float:left;
    position: relative;
    margin-right: @weuiUploaderFileSpacing;
    margin-bottom: @weuiUploaderFileSpacing;
    width: @weuiUploaderSize - @weuiUploaderBorderWidth * 2;
    height: @weuiUploaderSize - @weuiUploaderBorderWidth * 2;
    border: @weuiUploaderBorderWidth solid @weuiUploaderBorderColor;
    &:before, &:after{
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: @weuiUploaderBorderColor;
    }
    &:before{
        width: @weuiUploaderBorderWidth + 1;
        height: @weuiUploaderSize / 2;
    }
    &:after{
        width: @weuiUploaderSize / 2;
        height: @weuiUploaderBorderWidth + 1;
    }
    &:active{
        border-color: @weuiUploaderActiveBorderColor;
        &:before, &:after{
            background-color: @weuiUploaderActiveBorderColor;
        }
    }
}
.weui_uploader_input{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}