@import "../../base/fn";

.weui_grids {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        width: 200%;
        height: 200%;
        left: 0;
        top: 0;
        border: 1px solid @weuiGridBorderColor;
        transform-origin: 0 0;
        transform: scale(.5);
    }
}

.weui_grid {
    position: relative;
    float: left;
    padding: 20px 10px;
    width: 100% / @weuiGridColumnCount;
    box-sizing: border-box;

    &:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        width: 200%;
        height: 200%;
        left: 0;
        top: 0;
        border-bottom: 1px solid @weuiGridBorderColor;
        border-right: 1px solid @weuiGridBorderColor;
        transform-origin: 0 0;
        transform: scale(.5);
    }

    &:nth-child(3n) {
        &:before {
            border-right-width: 0;
        }
    }

    &:active {
        background-color: @itemActiveColor;
    }
}

.weui_grid_icon {
    width: @weuiGridIconSize;
    height: @weuiGridIconSize;
    margin: 0 auto;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    & + .weui_grid_label {
        margin-top: 5px;
    }
}

.weui_grid_label {
    display: block;
    text-align: center;
    color: @globalTitleColor;
    font-size: @weuiGridFontSize;
}