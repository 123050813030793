@import "../../base/fn";
@import "../weui_button/weui_button";

.weui_msg {
    padding-top: @weuiMsgPaddingTop;
    text-align: center;

    .weui_icon_area {
        margin-bottom: @weuiMsgIconGap;
    }

    .weui_text_area {
        margin-bottom: @weuiMsgTextGap;
        padding:0 20px;
    }
    .weui_msg_title {
        margin-bottom: @weuiMsgTitleGap;
        font-weight: 400;
        font-size: 20px;
    }
    .weui_msg_desc {
        font-size: 14px;
        color: @globalTextColor;
    }

    .weui_opr_area {
        margin-bottom: @weuiMsgOprGap;
    }

    .weui_extra_area {
        margin-bottom: @weuiMsgExtraAreaGap;
        font-size: 14px;
        color: @globalTextColor;
        a{color: @globalLinkColor;}
    }
}

@media screen and (min-height: @weuiMsgExtraAreaOfMinHeight) {
    .weui_extra_area {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
    }
}